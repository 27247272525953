
.inputLine {
  display: flex;
}

.inputLineInvalid label {
  color: red;
}

.inputLineInvalid input {
  border-color: red;
}

.inlineForm {
  margin: 0 -10px;
}

.inlineForm::after {
  content: ' ';
  clear: both;
  display: block;
}

.inlineForm .inputGroup {
  float: left;
  padding: 0 10px;
  width: 180px;
}

.inlineForm .button {
  margin-top: 1.4em;
  padding: .61em 10px;
  margin-left: 5px;
}

.input {
  width: 100%;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  border: solid 1px lightgray;
  outline: 0px !important;
  padding: 0 7px;
  line-height: 32px;
  box-sizing: border-box;
  background-color: white;
  height: 32px;
  display: inline-block;
  border-radius: 4px;
}

.input[disabled] {
  background-color: lightgray;
  cursor: default;
}

.inputLineDisabled {
  opacity: 0.5;
}

.label {
  padding-top: 5px;
  font-size: 12px;
  font-weight: bolder;
}

.checkboxWrapper, .inputGroup {
  margin-bottom: 10px;
}

.button {
  padding: 0 10px;
  height: 2.4em;
  color: white;
  background-color: gray;
  font-weight: bolder;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  border: 0;
  outline: none;
  vertical-align: middle;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 4px;
}

.smallButton {
  height: 1.6em;
}

.button:disabled {
  background-color: lightgray !important;
  color: rgb(77, 77, 77);
  pointer-events: none;
}

.buttonTransparent {
  border-radius: 5px;
  font-weight: bolder;
}

.buttonTransparent:disabled {
  background: white !important;
  opacity: .3;
}

.buttonConfirm {
  background-color: green;
}

.buttonConfirm.buttonTransparent {
  background-color: white;
  color: green;
  border: solid 1px green;
}

.buttonConfirm:hover {
  background-color: darkgreen
}

.buttonConfirm.buttonTransparent:hover {
  color: white;
  background-color: green;
}

.buttonWarning {
  background-color: orange;
}

.buttonWarning.buttonTransparent {
  background-color: white;
  color: darkorange;
  border: solid 1px darkorange;
}

.buttonWarning:hover {
  background-color: darkorange;
}

.buttonWarning.buttonTransparent:hover {
  color: white;
  background-color: darkorange;
}

.buttonInfo {
  background-color: rgb(56, 108, 228);
}

.buttonInfo.buttonTransparent {
  background-color: white;
  color: rgb(56, 108, 228);
  border: solid 1px rgb(56, 108, 228);
}

.buttonInfo:hover {
  background-color: blue
}

.buttonInfo.buttonTransparent:hover {
  color: white;
  background-color: rgb(56, 108, 228);
}

.buttonCancel {
  background-color: red;
}

.buttonCancel.buttonTransparent {
  background-color: white;
  color: red;
  border: solid 1px red;
}

.buttonCancel:hover {
  background-color: darkred;
}

.buttonCancel.buttonTransparent:hover {
  color: white;
  background-color: red;
}

.validationMessageGroup {
  color: red;
  margin-top: -.6em;
  min-height: 1.2em;
  font-size: .8em;
  text-align: right;
}

.checkboxWrapper label {
  font-size: .8em;
  display: block;
  margin-bottom: 1em;
  user-select: none;
}

.checkboxWrapper input {
  display: inline-block;
  margin-right: 0.4em;
  border: solid 1px gray;
  border-radius: 0;
  transform: translateY(0.1em);
  margin-left: 0;
}

.buttonInputLabelSpacing {
  margin-top: 1.4em;
}

.buttonFullWidth {
  width: 100%;
}

.textarea:hover {
  cursor: text;
}