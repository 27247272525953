html,
body {
  padding: 0;
  margin: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.align-center {
  text-align: center !important;
}

td::-webkit-scrollbar {
  display: none;
}

td.limit-size {
  max-width: 200px;
  overflow: auto;
  text-overflow: ellipsis;
}

td.limit-size:hover {
  text-overflow: unset;
}

.h-separator {
  padding: 2px 0;
  font-size: 20px;
  border-bottom: solid 1px lightgray;
  margin-bottom: 15px;
  margin-top: 10px;
}

.h-row {
  display: flex;
  margin: 0 -5px;
}

.h-col {
  padding: 0 5px;
  box-sizing: border-box;
}

.h-col-1 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 10%;
}

.h-col-2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
}

.h-col-3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30%;
}

.h-col-4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
}

.h-col-5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}

.h-col-6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60%;
}

.h-col-7 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 70%;
}

.h-col-8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80%;
}

.h-input-observation {
  padding: 2px 0;
  font-size: 12px;
  color: gray;
}

.h-ignore-input-spacing {
  margin-top: -12px;
}

.h-spacing {
  height: 15px;
}

.h-section-explanation {
  font-size: 12px;
  color: gray;
}

.h-tag {
  display: inline-block;
  font-size: 16px;
  border-radius: 6px;
  border: 2px solid;
  margin: 20px 5px 5px 0;
  padding: 5px;
  height: 20px;
  position: relative;
}

.h-tag-confirm {
  background-color: green;
}

.h-tag-unconfirm {
  background-color: red;
}

.h-tag-partial {
  background-color: yellow;
}

.h-bullet {
  display: inline-block;
  font-size: 12px;
  border-radius: 6px;
  background-color: red;
  margin: 0 5px 5px 0;
  height: 25px;
  line-height: 21px;
  user-select: none;
}

.h-tooltip-wrapper {
  position: relative;
}

.h-bullet .h-tooltip {
  position: absolute;
  background-color: black;
  color: white;
  z-index: 1000;
  padding: 5px;
  border-radius: 6px;
  top: calc(100% + 5px);
  width: max-content;
  opacity: 0;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
}

.h-tooltip-caret {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.h-tooltip-wrapper:hover .h-tooltip,
.h-tooltip-wrapper:hover .h-tooltip-caret {
  opacity: 1;
  transition: opacity 0.5s;
  transition-delay: 2s;
}

.h-bullet.h-bullet-confirm {
  background-color: green;
}

.h-bullet-content,
.h-bullet-btn {
  display: inline-block;
  padding: 2px 4px;
  height: 100%;
  box-sizing: border-box;
  color: white;
  font-weight: bolder;
}

.h-bullet-btn {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2px 6px;
  font-size: 14px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.h-bullet-btn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.h-form-label {
  font-size: 12px;
  font-weight: bolder;
}

.h-form-sub-label {
  font-size: 12px;
  margin: 5px 0;
}

.h-checkbox {
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 15px;
}

.h-link {
  color: blue;
  user-select: none;
  cursor: pointer;
}

.h-link:hover {
  text-decoration: underline;
}

.h-google-item {
  margin-bottom: 20px;
}

.h-google-item-caret {
  width: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #a5a5a5;
  position: relative;
  cursor: pointer;
}

.h-google-header:hover .h-google-item-caret {
  background-color: rgb(43, 43, 43);
}

.h-google-header:hover .h-google-item-caret * {
  color: white;
}

.h-google-item-caret * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.h-google-item-title {
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
}

.h-google-header-content {
  font-size: 14px;
}

.h-google-image {
  height: 100px;
  height: 100px;
  float: left;
  margin: 0 10px 10px 0;
}

.h-collapsed {
  display: none;
}

.h-pmo-main-row {
  align-items: flex-start;
}

.h-pmo-left-column {
  padding-right: 15px;
}

.h-pmo-references {
  overflow: auto;
  scroll-behavior: smooth;
  padding-bottom: 100vh;
}

.h-pmo-images {
  margin: 0 -5px;
}

.h-pmo-image-wrapper {
  width: 20%;
  box-sizing: border-box;
  padding: 5px;
  float: left;
}

.h-pmo-image {
  border: solid 1px lightgray;
  height: 100px;
  padding: 5px;
  position: relative;
}

.h-pmo-image:hover .h-pmo-hover {
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.h-pmo-image img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.h-pmo-image-zoom {
  text-align: center;
}

.h-dropzone {
  border: solid 1px black;
  height: 110px;
  width: 110px;
}

.h-brand-image {
  height: 100%;
  width: 100%;
  background-color: #fff;
  object-fit: contain;
}

.h-as-wrapper {
  position: relative;
  margin-bottom: 10px;
}

.h-as-label {
  padding-top: 5px;
  font-size: 12px;
  font-weight: bolder;
}

.h-as-select-box {
  width: 100%;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  border: solid 1px lightgray;
  outline: 0px !important;
  padding: 0 7px;
  line-height: 32px;
  box-sizing: border-box;
  background-color: white;
  height: 32px;
  overflow: hidden;
  border-radius: 4px;
}

.h-as-select-options {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border: solid 1px lightgray;
  border-top: 0;
  z-index: 1000;
  display: none;
}

.h-as-select-option-list {
  max-height: 200px;
  overflow: auto;
}

.h-as-select-options-open {
  display: block;
}

.h-as-search-input {
  width: 100%;
  border: 0;
  border-bottom: solid 1px lightgray;
  outline: 0px !important;
  padding: 6px;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;
}

.h-as-option {
  font-size: 13px;
  padding: 5px;
  user-select: none;
  cursor: pointer;
}

.h-as-option-selected {
  background-color: lightgray;
}

.h-as-option:hover {
  background-color: rgb(163, 163, 228);
}

.h-as-fetch-more {
  cursor: pointer;
  font-size: 13px;
  padding: 5px;
  user-select: none;
  border-top: solid 1px lightgray;
}

.h-as-fetch-more:hover {
  background-color: rgb(163, 163, 228);
}

.h-pmo-input-lock-wrapper {
  display: flex;
}

.h-pmo-input-lock-wrapper .h-input-observation {
  width: 50%;
}

.h-pmo-input-lock {
  text-align: right;
  color: blue;
  user-select: none;
  cursor: pointer;
}

.h-pmo-input-lock--unlocked {
  color: red;
}

.h-input-with-buttons {
  display: flex;
}

.h-input-with-buttons > *:nth-child(1) {
  flex-grow: 1;
}

.h-input-button {
  flex-grow: 0;
  display: block;
  height: 32px;
  margin-top: 1.35em;
  border-radius: 0;
  border: solid 1px lightgray;
  border-left: 0;
  cursor: pointer;
  border-radius: 2px;
}

.h-input-button:disabled {
  cursor: default;
}

.h-pmo-search-options {
  margin: 0.5em 0 1em 0;
}

.h-pmo-search-options label {
  margin-right: 0.5em;
}

.h-title-add-ons {
  float: right;
  white-space: nowrap;
  vertical-align: baseline;
}

.h-title-add-on {
  display: inline-block;
}

.h-title-add-on label {
  font-size: 0.7em;
  padding-right: 0.4em;
}

.h-title-add-on input {
  border: 0;
  outline: 0;
  font-size: 0.8em;
  font-family: inherit;
  width: 15em;
}

.h-title-add-on button {
  background-color: unset;
  border: 0;
  outline: 0;
  color: blue;
  cursor: pointer;
  padding: 0.5em 0;
  display: block;
  font-weight: bolder;
  text-transform: uppercase;
  transform: translateY(-0.1em);
}

.h-title-add-on button[disabled] {
  color: black;
  cursor: default;
}

.h-input {
  width: 100%;
  border: 0;
  border: solid 1px lightgray;
  outline: 0px !important;
  padding: 6px;
  box-sizing: border-box;
  background-color: white;
  height: 32px;
  overflow: hidden;
  cursor: default;
  border-radius: 2px;
}

.h-pmo-eanBoxWrapper {
  margin: 20px 0 -10px 0;
  width: 200px;
  display: inline-block;
}

/* Research Page */
.h-research form > div > div,
.h-research form div.col .h-research-period {
  display: flex;
}

.h-research form div.col {
  width: 100%;
  padding: 0 8px;
}

.h-research form div.col .h-research-period div {
  width: calc(100% - 8px);
}

.h-research form div.col .h-research-period div:nth-child(1) {
  margin-right: 16px;
}

.h-research form div.col .h-research-period {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.h-research form textarea {
  width: 100%;
  border: 0;
  border: solid 1px lightgray;
  outline: 0px !important;
  padding: 6px;
  box-sizing: border-box;
  background-color: white;
  height: 32px;
  overflow: hidden;
}

.h-research form .row-1,
.h-research form .row-2 {
  display: flex;
}

.h-research form > div > div {
  flex-direction: column;
}

.h-research form .row-2 ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  border: 1px solid lightgray;
  height: 350px;
  overflow: auto;
  display: block;
  margin-top: 0;
}

.h-research form .row-2 .companies {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  justify-content: flex-start;
}

.h-research form .companies ul li {
  padding: 8px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: inherit;
}

.h-research form .companies ul li:nth-last-child(1) {
  border-bottom: 0;
}

.h-research .h-research-period {
  display: flex;
  justify-content: space-between;
}

.h-research form .add-companies:nth-child(2) {
  align-items: center;
  display: flex;
}

.h-research form .companies ul li div span {
  padding: 0 8px;
}

.h-research form .companies ul li:hover {
  background-color: lightgray;
  cursor: pointer;
}

.h-search-loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-research-add {
  background: #248000;
  border: 0;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
}

.h-research-remove {
  background: red;
  border: 0;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  cursor: pointer;
}

.h-research-period > div:nth-child(1) {
  margin: 0 10px 0 0;
}

.h-economic-group-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.h-economic-group-div .h-checkbox {
  margin: 0px;
  padding: 6px;
}

.h-economic-group-div div:nth-child(1){
  width: 80%;
  min-width: 270px;
}

.h-table-companies-body tr td {
  max-width: 200px;
  white-space: pre-wrap;
  text-align: left;
}

.overlay {
  background-color: rgba(1, 1, 1, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.h-title-obs {
  margin-top: -5px;
  color: gray;
}