.separator {
  padding: 2px 0;
  font-size: 12px;
  border-bottom: solid 1px lightgray;
  margin-bottom: 15px;
}

.cnpjBoxWrapper {
  width: 300px;
}

.legend {
  font-size: 12px;
  color: gray;
  margin-bottom: 15px;
}

.inputWithButton {
  & > div {
    width: calc(100% - 100px);
    float: left;
  }

  button {
    margin-top: 19px;
    width: 100px;
    float: left;
  }

  ::after {
    content: ' ';
    display: block;
    clear: both;
  }
}

.searchInputWrapper {
  label {
    color: gray;
    font-weight: normal;
  }
}

.infoTable {
  width: 100%;
  font-size: 14px;
  white-space: nowrap;

  th {
    text-align: left;
    border-bottom: solid 1px black;
  }

  td {
    text-align: left;
    padding-bottom: 16px;
    width: 1px;
  }

  border-collapse: collapse;

}

.topInfo {
  display: flex;
  width: 100%;
}

.brandImage {
  height: 181px;
  width: 100%;
  background-color: #e6e7e8;
  object-fit: contain;
  border: solid 1px lightgray;
}

.salesTable {

  width: 100%;
  border-collapse: collapse;

  th {
    border-bottom: solid 1px black;
    padding: 5px;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 0; 
  }

  th:nth-child(2) {
    text-align: right;
    padding-right: 0; 
  }

  td:nth-child(2) {
    text-align: right;
  }

  tbody tr:nth-child(1) td {
    padding-top: 5px;
  }

}

.topic {
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px black;
  white-space: nowrap;
  padding: 5px 5px 5px 5px;
}

.info {
  font-size: 14px;
  white-space: nowrap;
  padding: 5px 5px 16px 5px;
}