.filters {
  display: flex;
  gap: 10px;
}

.moveButtonUp {
  display: none;
}

@media (max-width: 1000px) {
  .filters {
    display: block;
  }

  .moveButtonUp {
    margin-top: -16px;
    height: 1px;
    display: block;
  }
}

.readCouponCardWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .readCouponCardWrapper {
    grid-template-columns: 1fr;
  }
}

.readCouponMachineCardWrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .readCouponMachineCardWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.readCouponCard {
  background-color: white;
}

.readCouponCardTitle {
  padding: 0;
  margin: 0 0 5px 0;
}

.readCouponCardTable {
  border-collapse: collapse;
}

.readCouponCardTable tr:nth-child(2n) {
  background-color: lightgray;
}

.readCouponCardTable td, .readCouponCardTable th {
  white-space: nowrap;
  padding: 8px 5px;
}

.legend {
  width: 10px;
  height: 10px;
  border: solid 1px black;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 5px;
}

.machine {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.machineTitle {
  font-size: 20px;
  margin: 0;
  text-align: center;
}

.machineBulletDown {
  text-align: center;
  background-color: lightgray;
  padding: 5px;
  font-size: 20px;
  border: solid 1px black;
  background-color: orange;
}

.machineBulletBad {
  text-align: center;
  background-color: lightgray;
  padding: 5px;
  font-size: 20px;
  border: solid 1px black;
  background-color: #f5ea64
}

.machineBulletRegular {
  text-align: center;
  background-color: lightgray;
  padding: 5px;
  font-size: 20px;
  border: solid 1px black;
  background-color: #def1ff;
}

.machineBulletOk {
  text-align: center;
  background-color: lightgray;
  padding: 5px;
  font-size: 20px;
  border: solid 1px black;
  background-color: #6878ea;
}

.machineBulletGood {
  text-align: center;
  background-color: lightgray;
  padding: 5px;
  font-size: 20px;
  border: solid 1px black;
  background-color: #8695ff;
}

.machineBulletExcelent {
  text-align: center;
  background-color: lightgray;
  padding: 5px;
  font-size: 20px;
  border: solid 1px black;
  color: white;
  background-color: #33468a;
}