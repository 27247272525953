
.subTitle {
  border-bottom: solid 1px black;
}

.subTitle2 {
  font-size: 1.05em;
  border-bottom: solid 1px lightgray;
}

.disabledWarning {
  margin-top: -1em;
  color: red;
}