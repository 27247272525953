
.up {
  background-color: green;
  color: white;
  padding: 2px 5px;
  font-weight: bolder;
  border-radius: 5px;
}

.down {
  background-color: red;
  color: white;
  padding: 2px 5px;
  font-weight: bolder;
  border-radius: 5px;
}

.other {
  background-color: orange;
  color: white;
  padding: 2px 5px;
  font-weight: bolder;
  border-radius: 5px;
}

.chartsWrapper {
  margin: 0 -10px;
}

.chartsWrapper::after {
  content: ' ';
  clear: both;
  display: block;
}

.mainChartWrapper {
  height: 300px;
  padding: 0 10px;
  margin-top: 20px;
}

.secondaryChartWrapper {
  height: 300px;
  width: 50%;
  padding: 0 10px;
  margin-top: 20px;
  box-sizing: border-box;
  float: left;
}

.removeButton {
  font-size: 1.1em;
  background-color: red;
  margin-right: .4em;
  color: white;
  font-weight: bolder;
  border: 0;
}

.removeButton:hover {
  background-color: darkred;
  cursor: pointer;
}

.removeLine {
  margin-bottom: 1em;
  font-size: .8em;
}

.removeLines {
  margin-top: -.7em;
  margin-bottom: 1.2em;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: #000000cf;
  color: #fff;
  text-align: center;
  padding: 3px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}


