.dropdownMenu {
  align-self: self-end;
  position:relative;
}

.dropdownMenu ul{
  position:absolute;
  top:25px;
  background-color:#fff;
  display:none;
}

.dropdownMenu:hover ul{
  display:block;
  padding: 5px;
}

.dropdownMenu:hover ul div{
  display:block;
  width:150px;
  padding:5px;
}

.dropdownMenu ul div:hover{
  background:#333;
  color:#fff;
  cursor:pointer;
}