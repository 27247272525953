.cardWrapper {
    display: flex;
    gap: 15px;
}

.card {
    flex: 1;
    border: solid 1px lightgray;
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    height: 340px;
}

.instanceTitleTop {
    font-size: 16px;
}

.instanceTitle {
    font-size: 20px;
    font-weight: bolder;
    line-height: 14px;
}

.instanceSpacer {
    height: 25px;
}

.instanceSpaceRemaining {
    flex: 1;
}

.instancePropertyName {
    font-weight: bolder;
}

.instanceStatusWrapper {
    display: flex;
    gap: 10px
}

.statusIndicator {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: gray;
    border-radius: 50%;
}

.statusIndicatorActive {
    background-color: green;
}

.statusIndicatorChanging {
    background-color: orange;
}

.statusIndicatorInactive {
    background-color: red;
}

.toggleModalWrapper {
    display: flex;
    flex-direction: column;
}

.toggleModalTitle {
    font-weight: bolder;
    font-size: 20px;
}

.toggleModalMessageTitle {
    font-weight: bolder;
}
.toggleModalSpacer1 {
    height: 25px;
}

.toggleModalSpacer2 {
    height: 55px;
}