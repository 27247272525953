.tabs {
  margin: 0 0 .4em 0;
  padding: 0;
}

.tabs li {
  font-size: .9em;
  list-style: none;
  display: inline-block;
}

.tabs li a,
.tabs li button {
  background-color: white;
  cursor: pointer;
  padding: .5em;
  color: black;
  text-decoration: none;
  display: block;
  margin: 0 .5em .5em 0;
  border-radius: 5px;
  border: solid 1px lightgray;
}

.tabs li a:hover,
.tabs li button:hover {
  background-color: lightgray;
}

.activeTab {
  background-color: rgb(153,21,43) !important;
  border: solid 1px rgb(153,21,43) !important;
  color: white !important;
}

.table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
}

.table thead {
  background-color: lightgray;
}

.table th, .table td {
  padding: 5px 8px;
  text-align: center;
  width: 1px;
  white-space: nowrap;
}

.table th {
  padding: 12px 8px;
  background-color: rgb(153,21,43);
  color: white;
  font-size: .9em;
}

.table tr:nth-child(2n) td {
  background-color: rgb(233 233 233);
}

.table tr:hover td {
  background-color: lightblue;
}

.table2 {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
  border-collapse: collapse;
}

.table2 td {
  padding: 5px;
  text-align: left;
  white-space: nowrap;
  border: solid 1px black;
}

.table2 th:first-child {
  padding-left: 0;
}

.table2 td:first-child {
  border-left: 0;
  padding-left: 0;
  font-weight: bolder;
}

.table2 td:last-child {
  border-right: 0;
  padding-right: 0;
}

.table2 tr:first-child td {
  border-top: 0;
}

.table2 tr:last-child td {
  border-bottom: 0;
}

.left {
  text-align: left !important;
}

.littleWidth{
  width: 25% !important; ;
}

.fullWidth {
  width: 100% !important;
}

.middleWidth {
  width: 50% !important;
}

.center {
  text-align: center !important;
}

.right {
  text-align: right !important;
}

.pagination {
  margin: 5px 0;
  text-align: center;
}

.paginationItem {
  display: inline-block;
  background-color: rgb(153,21,43);
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  color: white;
  margin-right: 5px;
}

.paginationItem:hover, .paginationItemActive {
  background-color: red;
}

.paginationItemDisabled {
  background-color: lightgray;
  cursor: default;
}

.sectionTitle {
  font-size: 20px;
  margin: 0;
  font-weight: bolder;
  padding-bottom: 5px;
  border-bottom: solid 1px gray;
}

.sectionTitleDescription {
  margin: 5px 0 15px 0;
  font-size: 14px;
  color: gray;
}

.alert {
  font-size: 14px;
  padding: 15px;
  border: solid 1px black;
  border-radius: 5px;
}

.alertInfo {
  border-color: #cce5ff;
  background-color: #cce5ff;
  color: #004085;
}

.alertTitle {
  font-weight: bolder;
  margin-bottom: 5px;
}

.alertWarn {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.scrollTable {
  border-spacing: 0;
  font-size: 14px;
}

.scrollTable .thead, .tbody tr{ 
  display:table;
  width:100%;
  table-layout:fixed;  
}

.scrollTable tbody{
  display: block;
  max-height: 350px;
  overflow: auto;
}

.scrollTable .thead { 
  width: 100%
}

.scrollTable th,
.scrollTable td {
  padding: 5px;
}

.scrollTable th {
  padding: 12px 8px;
  background-color: rgb(153,21,43);
  color: white;
  font-size: .9em;
}

.scrollTable tr:nth-child(2n) td {
  background-color: rgb(233 233 233);
}

.scrollTable tr:hover td {
  background-color: lightblue;
}

.gridTable {
  display: grid;
  font-size: .9em;
}

.gridTable > div {
  padding: 5px;
  display: flex;
  align-items: center;
}

.gridTable .gridTableHeader {
  background-color: rgb(153,21,43);
  color: white;
  font-size: .9em;
  font-weight: bolder;
  padding: 12px 8px;
  white-space: nowrap;
}

.bgRow {
  background-color: #ebebeb;
}

.gridTableStickyHeader {
  position: sticky;
  top: 0;
}

.row {
  margin: 0 -10px;
}

.row:after {
  clear: both;
  content: ' ';
  display: block;
}

.col {
  float: left;
  min-height: 1px;
  padding: 0 10px;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .col {
    width: 100% !important;
  }
}

.noBreak {
  white-space: nowrap;
}

.flexCenter {
  justify-content: center;
}

.removeButton {
  font-size: 1.1em;
  background-color: red;
  margin-right: .4em;
  color: white;
  font-weight: bolder;
  border: 0;
}

.removeButton:hover {
  background-color: darkred;
  cursor: pointer;
}

.removeLine {
  margin-bottom: 1em;
  font-size: .8em;
}

.removeLines {
  margin-top: -.7em;
  margin-bottom: 1.2em;
}

.platform {
  font-family: arial;
  padding-top: 40px;
}

.platformHeader {
  background-color: rgb(153,21,43);
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  gap: 5px;
  justify-content: space-between;
  box-sizing: border-box;
}

.platformHeaderLeft {
  display: flex;
  gap: 5px;
}

.platformHeaderRight {
  display: flex;
  height: 100%;
}

.platformHeaderRight button {
  display: block;
  height: 100%;
  background-color: transparent;
  border: 0;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 15px;
  cursor: pointer;
}

.platformHeaderRight button:hover {
  background-color: rgb(92, 12, 26);
}

.platformLogo img {
  cursor: pointer;
  height: 25px;
  transform: translateY(2px);
}

.platformLeftMenu {
  height: calc(100vh - 40px);
  width: 240px;
  background-color: #2f3640;
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 9;
  overflow: auto;
  box-sizing: border-box;
  padding: 10px 0;
  display: none;
}

@media (max-width: 600px) {
  .platformLeftMenu {
    width: 100%;
    text-align: center;
  }
}

.platformMenuOpen .platformLeftMenu {
  display: block;
}

.platformBody {
  padding: 0 10px 10px 10px;
  box-sizing: border-box;
  min-height: calc(100vh - 55px);
}

@media (min-width: 1024px) {
  .platformMenuOpen .platformBody {
    padding-left: 250px;
  }
}

.menuTitle {
  color: white;
  font-weight: bolder;
  padding: 5px;
}

.subMenuTitle {
  color: white;
  padding: 6px 5px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  text-decoration: none;
}

.subMenuTitle:hover {
  background-color: black;
}

.activeSubMenuTitle {
  background-color: white !important;
  color: black;
}

.subMenuTitleFocused {
  background-color: rgb(153,21,43);
}

.menuSection {
  margin-bottom: 20px;
}

.platformLeftMenu::-webkit-scrollbar {
  width: 7px;
}

.platformLeftMenu::-webkit-scrollbar-track {
  background-color: #647181;
  border-left: solid 1px rgba(255, 255, 255, 0.2);
}

.platformLeftMenu::-webkit-scrollbar-thumb {
  background-color: rgb(153,21,43);
  border-left: solid 1px rgba(255, 255, 255, 0.2);
}

.platformLeftMenu::-webkit-scrollbar-button {
  display: none;
}

.platformMenuIcon {
  font-size: 20px;
  color: white;
  cursor: pointer;
  padding: 4px 6px;
}

.platformMenuOpen .platformMenuIcon {
  background-color: white;
  color: rgb(153,21,43);
}

.platformLeftMenuFilter {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: solid 1px white;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
  outline: 0 !important;
}

.platformLeftMenuFilter::placeholder {
  color: white;
}

.platformLeftMenuFilterWrapper {
  padding: 5px;
}

.imageWrapper {
  display: flex;
  height: 60px;
  width: 60px;
  border: solid 1px black;
  justify-content: space-around;
  flex-direction: column;
}

.imageWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.imageWrapper .imageMissing {
  width: 100%;
  word-wrap: break-word;
  font-size: 12px;
}

.textCenter {
  text-align: center;
}

.alignCenter {
  text-align: -webkit-center;
}

.justifyTextHeight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.widthFiller {
  width: -webkit-fill-available;
}