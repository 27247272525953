
.addUserToGroupForm {
  display: flex;
}

.addUserToGroupForm > div {
  margin-bottom: 0;
  width: 100%;
}

.addUserToGroupForm > div > div:nth-child(1) {
  display: none;
}