.imageWrapper {
    display: flex;
    height: 60px;
    width: 60px;
    border: solid 1px black;
    justify-content: space-around;
    flex-direction: column;
}

.imageWrapper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.imageWrapper .imageMissing {
    width: 100%;
    word-wrap: break-word;
    font-size: 12px;
}