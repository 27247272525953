.removeButton {
  font-size: 1.1em;
  background-color: red;
  margin-right: .4em;
  color: white;
  font-weight: bolder;
  border: 0;
}

.removeButton:hover {
  background-color: darkred;
  cursor: pointer;
}

.removeLine {
  margin-bottom: 1em;
  font-size: .8em;
}

.removeLines {
  margin-top: -.7em;
  margin-bottom: 1.2em;
}